import { EventEmitter, Injectable } from '@angular/core';
import { Subscription, timer } from 'rxjs';
import { Auth0DecodedHash, Auth0UserProfile, WebAuth } from 'auth0-js';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AppMetadata } from '../../core/models/app-metadata';
import { UserMetadata } from '../../core/models/user-metadata';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private _accessToken: string = '';
  private _userProfile?: Auth0UserProfile;
  private _userRoles: string[] = [];
  private _userPermissions: string[] = [];
  private _appMetadata?: AppMetadata;
  private _userMetadata?: UserMetadata;
  private auth0WebAuth: WebAuth;

  public userLoggedIn$: EventEmitter<Auth0UserProfile> = new EventEmitter();

  public get isAuthenticated(): boolean {
    const expiresAt = JSON.parse(localStorage.getItem('expiresAt') || '{}');
    return new Date().getTime() < expiresAt;
  }

  public get userProfile(): Auth0UserProfile | undefined {
    if (this._userProfile)
      return this._userProfile;

    const userProfile = localStorage.getItem('userProfile');

    if (userProfile) {
      this._userProfile = JSON.parse(userProfile);
    }

    return this._userProfile;
  }

  public get currentUserID(): string | undefined {
    return this.userProfile?.user_id ? this.userProfile?.user_id.split('|')[1] : this.userProfile?.sub.split('|')[1];
  }

  public get loginProvider(): string | undefined {
    return this.userProfile?.user_id ? this.userProfile?.user_id.split('|')[0] : this.userProfile?.sub.split('|')[0];
  }

  public get userRoles(): string[] {
    if (this._userRoles)
      return this._userRoles;

    this._userRoles = this.getRolesFromToken();
    return this._userRoles;
  }

  public get userPermissions(): string[] {
    if (this._userPermissions)
      return this._userPermissions;

    this._userPermissions = this.getPermissionsFromToken();
    return this._userPermissions;
  }

  public get appMetadata(): AppMetadata | undefined {
    if (this._appMetadata)
      return this._appMetadata;

    this._appMetadata = this.getAppMetadataFromToken();
    return this._appMetadata;
  }

  public get userMetadata(): UserMetadata | undefined {
    if (this._userMetadata)
      return this._userMetadata;

    this._userMetadata = this.getUserMetadataFromToken();
    return this._userMetadata;
  }

  public get accessToken(): string {
    if (this._accessToken)
      return this._accessToken;

    const accessToken = localStorage.getItem('accessToken');

    if (accessToken) {
      this._accessToken = accessToken;
    }

    return this._accessToken;
  }

  constructor(private toastrService: ToastrService, private router: Router) {
    this.auth0WebAuth = new WebAuth({
      clientID: environment.auth.clientId,
      domain: environment.auth.domain,
      responseType: environment.auth.responseType,
      redirectUri: environment.auth.redirectURI,
      audience: environment.auth.apiIdentifier,
      scope: environment.auth.scope
    });
  }

  public login(email: string, password: string, callback: () => void): void {
    this.auth0WebAuth.login({
      realm: environment.auth.realm,
      email,
      password
    }, error => {
      if (error) {
        const errorMessage = error.error_description ?? error.errorDescription;
        this.toastrService.error(errorMessage, undefined, {
          closeButton: false,
          progressBar: false,
          positionClass: 'toast-top-center'
        });
        callback();
      }
    });
  }

  public register(email: string, password: string, callback: () => void): void {
    this.auth0WebAuth.signup({
      email,
      password,
      connection: environment.auth.realm,
      userMetadata: {
        userAgreement: 'I have read and agree with BurningLeads Terms of Services & Privacy Policy.'
      }
    }, (error, result) => {
      if (result) {
        this.login(email, password, callback);
      } else if (error) {
        if (error.code === 'invalid_signup') {
          error.error_description = 'Email already exists.';
        }
        const errorMessage = error.error_description ?? error.errorDescription;
        this.toastrService.error(errorMessage, undefined, {
          closeButton: false,
          progressBar: false,
          positionClass: 'toast-top-center'
        });
        callback();
      }
    });
  }

  public forgotPassword(email: string, callback: () => void): void {
    this.auth0WebAuth.changePassword({
      email,
      connection: environment.auth.realm
    }, (error, result) => {
      if (result) {
        callback();
      } else if (error) {
        const errorMessage = error.error_description ?? error.errorDescription;
        this.toastrService.error(errorMessage, undefined, {
          closeButton: false,
          progressBar: false,
          positionClass: 'toast-top-center'
        });
      }
    });
  }

  public signInWithGoogle(): void {
    this.auth0WebAuth.authorize({
      connection: 'google-oauth2'
    });
  }

  public signInWithLinkedin(): void {
    this.auth0WebAuth.authorize({
      connection: 'linkedin'
    });
  }

  public handleAuthentication(): void {
    this.auth0WebAuth.parseHash((error, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.localLogin(authResult);
        this.getProfile(authResult.accessToken);
        this.router.navigate(['/']);
      } else if (error) {
        const errorMessage = error.error_description ?? error.errorDescription;
        if (errorMessage === 'email_not_verified') {
          this.router.navigate(['/auth/email-verification']);
        }
        else {
          this.toastrService.error(errorMessage, undefined, {
            closeButton: false,
            progressBar: false,
            positionClass: 'toast-top-center'
          });
          this.router.navigate(['/auth/sign-in']);
        }
      }
    });
  }

  public isInRole(roleName: string): boolean {
    return this.userRoles ? this.userRoles.includes(roleName) : false;
  }

  public hasPermissions(permissions: string[]): boolean {
    return this.userPermissions ? this.userPermissions.some(permission => permissions.includes(permission)) : false;
  }

  public renewToken(): void {
    this.unscheduleRenewal();
    if (localStorage.getItem('isLoggedIn') === 'true') {
      this.auth0WebAuth.checkSession({}, (error, authResult) => {
        if (authResult && authResult.accessToken && authResult.idToken) {
          this.localLogin(authResult);
        } else if (error) {
          console.log(`Could not get a new token(${error.error}: ${error.error_description}).`);
        }
      });
    }
  }

  public logout(): void {
    this._accessToken = '';
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('expiresAt');
    localStorage.removeItem('userProfile');
    localStorage.removeItem('accessToken');

    this.auth0WebAuth.logout({
      clientID: environment.auth.clientId,
      returnTo: environment.auth.logoutReturnTo
    });

    this.unscheduleRenewal();
  }

  private getProfile(accessToken: string) {
    this.auth0WebAuth.client.userInfo(accessToken, (error, profile) => {
      if (profile) {
        this._userProfile = profile;
        localStorage.setItem('userProfile', JSON.stringify(this._userProfile));
        this.userLoggedIn$.emit(this._userProfile);
      } else if (error) {
        console.warn(`Error retrieving profile: ${error.error}`);
      }
    });
  }

  private getRolesFromToken(): string[] {
    let roles: string[] = [];
    if (this.accessToken) {
      const decodedToken = new JwtHelperService().decodeToken(this.accessToken);
      if (decodedToken) {
        roles = decodedToken[`${environment.auth.apiIdentifier}/roles`];
      }
    }
    return roles;
  }

  private getPermissionsFromToken(): string[] {
    let permissions: string[] = [];
    if (this.accessToken) {
      const decodedToken = new JwtHelperService().decodeToken(this.accessToken);
      if (decodedToken && Array.isArray(decodedToken['permissions'])) {
        permissions = decodedToken['permissions'];
      }
    }
    return permissions;
  }

  private getAppMetadataFromToken(): AppMetadata | undefined {
    let appMetadata: AppMetadata | undefined = undefined;
    if (this.accessToken) {
      const decodedToken = new JwtHelperService().decodeToken(this.accessToken);
      if (decodedToken) {
        appMetadata = decodedToken[`${environment.auth.apiIdentifier}/app_metadata`];
      }
    }
    return appMetadata;
  }

  private getUserMetadataFromToken(): UserMetadata | undefined {
    let userMetadata: UserMetadata | undefined = undefined;
    if (this.accessToken) {
      const decodedToken = new JwtHelperService().decodeToken(this.accessToken);
      if (decodedToken) {
        userMetadata = decodedToken[`${environment.auth.apiIdentifier}/user_metadata`];
      }
    }
    return userMetadata;
  }

  private localLogin(authResult: Auth0DecodedHash): void {
    localStorage.setItem('isLoggedIn', 'true');

    const expiresAt = authResult.expiresIn! * 1000 + new Date().getTime();
    localStorage.setItem('expiresAt', JSON.stringify(expiresAt));

    this._accessToken = authResult.accessToken!;
    localStorage.setItem('accessToken', this._accessToken);

    if (authResult.idTokenPayload) {
      this._userRoles = authResult.idTokenPayload[`${environment.auth.apiIdentifier}/roles`];
      this._userPermissions = authResult.idTokenPayload['permissions'];
      this._appMetadata = authResult.idTokenPayload[`${environment.auth.apiIdentifier}/app_metadata`];
      this._userMetadata = authResult.idTokenPayload[`${environment.auth.apiIdentifier}/user_metadata`];
    }

    this.scheduleRenewal();
  }

  refreshSubscription?: Subscription;
  private scheduleRenewal() {
    if (!this.isAuthenticated) return;

    const expiresAt = JSON.parse(localStorage.getItem('expiresAt') || '{}');
    const now = Date.now();

    var refreshAt = expiresAt - (1000 * 30);
    const source = timer(Math.max(1, refreshAt - now));

    this.refreshSubscription = source.subscribe(() => {
      this.renewToken();
    });
  }

  private unscheduleRenewal() {
    if (!this.refreshSubscription) return;
    this.refreshSubscription.unsubscribe();
  }

  // public requestResetPassword(captchaCode: string, email: string): Observable<boolean> {
  //   return this.httpClient.post<boolean>(this._baseUrl + '/api/users/requestResetPassword', { email: email },
  //     { headers: { captchaCode: captchaCode } });
  // }

  // public validateResetPasswordCode(code: string): Observable<boolean> {
  //   return this.httpClient.post<boolean>(this._baseUrl + '/api/users/validateResetPasswordCode', { code: code });
  // }

  // public resetPassword(code: string, password: string): Observable<boolean> {
  //   const options = { headers: { 'X-Ignore-Logging': 'true' } };
  //   return this.httpClient.post<boolean>(this._baseUrl + '/api/users/resetPassword', { code: code, password: password }, options);
  // }

}