import { MenuItem } from './menu-item';

export const MENU: MenuItem[] = [
  {
    id: 1,
    label: 'sideBar.leadGeneration',
    link: ''
  },
  {
    id: 2,
    label: 'sideBar.dataEnrichment',
    link: 'data-enrichment'
  },
  {
    id: 3,
    label: 'sideBar.dataExtraction',
    link: 'data-extraction',
    hasSeperator: true
  },
  {
    id: 4,
    label: 'sideBar.queries',
    link: 'queries',
    icon: 'bx bx-layer',
  },
  {
    id: 5,
    label: 'sideBar.dashboard',
    link: 'dashboard',
    icon: 'bx bx-pie-chart-alt-2',
  },
  {
    id: 6,
    label: 'sideBar.settings',
    link: 'settings',
    icon: 'bx bx-cog',
    showOnFooter: true
  },
  {
    id: 7,
    label: 'sideBar.help',
    link: 'help',
    icon: 'bx bx-help-circle'
  }
];
