import { Injectable } from '@angular/core';
import {
    Firestore,
    Timestamp,
    DocumentReference,
    doc,
    docSnapshots,
    CollectionReference,
    collection,
    collectionChanges,
    Query,
    docData,
    collectionData,
    addDoc,
    getDoc,
    getDocs,
    DocumentSnapshot,
    setDoc
} from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map, distinctUntilChanged, catchError } from 'rxjs/operators';
import { Auth, signInWithEmailAndPassword, signOut } from '@angular/fire/auth';
import { isEqual } from 'lodash';
import { environment } from '../../../environments/environment';

type DocPredicate<T> = string | DocumentReference<T>;
type CollectionPredicate<T> = string | CollectionReference<T>;

@Injectable({
    providedIn: 'root'
})
export class FirestoreService {
    constructor(
        private angularFirestore: Firestore,
        private angularFireAuth: Auth
    ) {
        this.signIn();
    }

    // Sign in to Firebase Auth
    async signIn(): Promise<void> {
        try {
            await signInWithEmailAndPassword(
                this.angularFireAuth,
                environment.firebaseAuth.email,
                environment.firebaseAuth.password
            );
        } catch (error) {
            console.error('Error signing in:', error);
            throw error;
        }
    }

    // Sign out from Firebase Auth
    async signOut(): Promise<void> {
        try {
            await signOut(this.angularFireAuth);
        } catch (error) {
            console.error('Error signing out:', error);
            throw error;
        }
    }

    // Get the current server date/time
    serverDateTime(): Date {
        return Timestamp.now().toDate() || new Date();
    }

    // Get a document reference
    doc<T>(ref: DocPredicate<T>): DocumentReference<T> {
        return typeof ref === 'string'
            ? (doc(this.angularFirestore, ref) as DocumentReference<T>)
            : ref;
    }

    // Get real-time document snapshots as Observable
    docSnapshots$<T>(ref: DocPredicate<T>): Observable<T> {
        return docSnapshots<T>(this.doc(ref)).pipe(
            map(doc => doc.data() as T),
            distinctUntilChanged(isEqual),
            catchError(error => {
                console.error('Error in docSnapshots$:', error);
                throw error;
            })
        );
    }

    // Get document snapshot asynchronously
    async getDocSnapshotAsync<T>(ref: DocPredicate<T>): Promise<DocumentSnapshot<T>> {
        try {
            return await getDoc(this.doc(ref));
        } catch (error) {
            console.error('Error fetching document snapshot:', error);
            throw error;
        }
    }

    // Get document value asynchronously
    async getDocValueAsync<T>(ref: DocPredicate<T>): Promise<T | undefined> {
        try {
            return (await this.getDocSnapshotAsync(ref)).data();
        } catch (error) {
            console.error('Error fetching document value:', error);
            throw error;
        }
    }

    // Get real-time document data as Observable
    docValueChanges$<T>(ref: DocPredicate<T>): Observable<T | undefined> {
        return docData<T>(this.doc(ref)).pipe(
            distinctUntilChanged(isEqual),
            catchError(error => {
                console.error('Error in docValueChanges$:', error);
                throw error;
            })
        );
    }

    // Get a collection reference
    col<T>(ref: CollectionPredicate<T>): CollectionReference<T> {
        return typeof ref === 'string'
            ? (collection(this.angularFirestore, ref) as CollectionReference<T>)
            : ref;
    }

    // Get real-time collection snapshots as Observable
    colSnapshots$<T>(queryRef: Query<T>): Observable<T[]> {
        return collectionChanges<T>(queryRef).pipe(
            map(docs => docs.map(a => a.doc.data()) as T[]),
            distinctUntilChanged(isEqual),
            catchError(error => {
                console.error('Error in colSnapshots$:', error);
                throw error;
            })
        );
    }

    // Get collection data asynchronously
    async getCollectionAsync<T>(queryRef: Query<T>): Promise<T[]> {
        try {
            return (await getDocs(queryRef)).docs.map(doc => doc.data());
        } catch (error) {
            console.error('Error fetching collection data:', error);
            throw error;
        }
    }

    // Get real-time collection data as Observable
    colValueChanges$<T>(queryRef: Query<T>): Observable<T[]> {
        return collectionData<T>(queryRef).pipe(
            distinctUntilChanged(isEqual),
            catchError(error => {
                console.error('Error in colValueChanges$:', error);
                throw error;
            })
        );
    }

    // Add a new document to a collection
    async addDoc<T>(reference: CollectionReference<T>, data: T): Promise<DocumentReference<T>> {
        try {
            const sanitizedData = this.sanitizeData(data);
            return await addDoc(reference, sanitizedData as any);
        } catch (error) {
            console.error('Error adding document:', error);
            throw error;
        }
    }

    // Set a document in a collection
    async setDoc<T>(reference: DocumentReference<T>, data: T): Promise<void> {
        try {
            const sanitizedData = this.sanitizeData(data);
            await setDoc(reference, sanitizedData as any);
        } catch (error) {
            console.error('Error setting document:', error);
            throw error;
        }
    }

    // Utility method to sanitize data by removing undefined values
    private sanitizeData<T>(data: T): Partial<T> {
        return Object.fromEntries(
            Object.entries(data as any).filter(([_, v]) => v !== undefined)
        ) as Partial<T>;
    }
}
