import * as i0 from '@angular/core';
import { Version, inject, NgZone, Injectable, isDevMode, runInInjectionContext, PendingTasks, Injector } from '@angular/core';
import { getApps } from 'firebase/app';
import { pendingUntilEvent } from '@angular/core/rxjs-interop';
import { queueScheduler, asyncScheduler, Observable } from 'rxjs';
import { observeOn, subscribeOn } from 'rxjs/operators';
const VERSION = new Version('ANGULARFIRE2_VERSION');
const ɵisSupportedError = module => `The APP_INITIALIZER that is "making" isSupported() sync for the sake of convenient DI has not resolved in this
context. Rather than injecting ${module} in the constructor, first ensure that ${module} is supported by calling
\`await isSupported()\`, then retrieve the instance from the injector manually \`injector.get(${module})\`.`;
function ɵgetDefaultInstanceOf(identifier, provided, defaultApp) {
  if (provided) {
    // Was provide* only called once? If so grab that
    if (provided.length === 1) {
      return provided[0];
    }
    const providedUsingDefaultApp = provided.filter(it => it.app === defaultApp);
    // Was provide* only called once, using the default app? If so use that
    if (providedUsingDefaultApp.length === 1) {
      return providedUsingDefaultApp[0];
    }
  }
  // Grab the default instance from the defaultApp
  const defaultAppWithContainer = defaultApp;
  const provider = defaultAppWithContainer.container.getProvider(identifier);
  return provider.getImmediate({
    optional: true
  });
}
const ɵgetAllInstancesOf = (identifier, app) => {
  const apps = app ? [app] : getApps();
  const instances = [];
  apps.forEach(app => {
    const provider = app.container.getProvider(identifier);
    provider.instances.forEach(instance => {
      if (!instances.includes(instance)) {
        instances.push(instance);
      }
    });
  });
  return instances;
};
class ɵAppCheckInstances {
  constructor() {
    return ɵgetAllInstancesOf(ɵAPP_CHECK_PROVIDER_NAME);
  }
}
const ɵAPP_CHECK_PROVIDER_NAME = 'app-check';

/* eslint-disable @typescript-eslint/ban-ts-comment */
/**
 * Schedules tasks so that they are invoked inside the Zone that is passed in the constructor.
 */
class ɵZoneScheduler {
  zone;
  delegate;
  constructor(zone, delegate = queueScheduler) {
    this.zone = zone;
    this.delegate = delegate;
  }
  now() {
    return this.delegate.now();
  }
  schedule(work, delay, state) {
    const targetZone = this.zone;
    // Wrap the specified work function to make sure that if nested scheduling takes place the
    // work is executed in the correct zone
    const workInZone = function (state) {
      if (targetZone) {
        targetZone.runGuarded(() => {
          work.apply(this, [state]);
        });
      } else {
        work.apply(this, [state]);
      }
    };
    // Scheduling itself needs to be run in zone to ensure setInterval calls for async scheduling are done
    // inside the correct zone. This scheduler needs to schedule asynchronously always to ensure that
    // firebase emissions are never synchronous. Specifying a delay causes issues with the queueScheduler delegate.
    return this.delegate.schedule(workInZone, delay, state);
  }
}
class ɵAngularFireSchedulers {
  outsideAngular;
  insideAngular;
  constructor() {
    const ngZone = inject(NgZone);
    this.outsideAngular = ngZone.runOutsideAngular(() => new ɵZoneScheduler(typeof Zone === 'undefined' ? undefined : Zone.current));
    this.insideAngular = ngZone.run(() => new ɵZoneScheduler(typeof Zone === 'undefined' ? undefined : Zone.current, asyncScheduler));
  }
  static ɵfac = function ɵAngularFireSchedulers_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || ɵAngularFireSchedulers)();
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: ɵAngularFireSchedulers,
    factory: ɵAngularFireSchedulers.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ɵAngularFireSchedulers, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [], null);
})();
function getSchedulers() {
  return inject(ɵAngularFireSchedulers);
}
var alreadyWarned = false;
function warnOutsideInjectionContext(original, operation) {
  if (isDevMode()) {
    console.warn(`Firebase API called outside injection context: ${operation}(${original.name})`);
    if (!alreadyWarned) {
      alreadyWarned = true;
      console.error("Calling Firebase APIs outside of an Injection context may destabilize your application leading to subtle change-detection and hydration bugs. Find more at https://github.com/angular/angularfire/blob/main/docs/zones.md");
    }
  }
}
function runOutsideAngular(fn) {
  let ngZone;
  try {
    ngZone = inject(NgZone);
  } catch (e) {
    warnOutsideInjectionContext(fn, "runOutsideAngular");
  }
  if (!ngZone) {
    return fn();
  }
  return ngZone.runOutsideAngular(() => fn());
}
function run(fn) {
  let ngZone;
  try {
    ngZone = inject(NgZone);
  } catch (e) {
    warnOutsideInjectionContext(fn, "run");
  }
  if (!ngZone) {
    return fn();
  }
  return ngZone.run(() => fn());
}
function observeOutsideAngular(obs$) {
  let schedulers;
  try {
    schedulers = getSchedulers();
  } catch (e) {
    warnOutsideInjectionContext(obs$, "observeOutsideAngular");
  }
  if (!schedulers) {
    return obs$;
  }
  return obs$.pipe(observeOn(schedulers.outsideAngular));
}
function observeInsideAngular(obs$) {
  let schedulers;
  try {
    schedulers = getSchedulers();
  } catch (e) {
    warnOutsideInjectionContext(obs$, "observeInsideAngular");
  }
  if (!schedulers) {
    return obs$;
  }
  return obs$.pipe(observeOn(schedulers.insideAngular));
}
const zoneWrapFn = (it, taskDone, injector) => {
  return (...args) => {
    if (taskDone) {
      setTimeout(taskDone, 0);
    }
    return runInInjectionContext(injector, () => run(() => it.apply(this, args)));
  };
};
const ɵzoneWrap = (it, blockUntilFirst) => {
  // function() is needed for the arguments object
  return function () {
    let taskDone;
    const _arguments = arguments;
    let schedulers;
    let pendingTasks;
    let injector;
    try {
      schedulers = getSchedulers();
      pendingTasks = inject(PendingTasks);
      injector = inject(Injector);
    } catch (e) {
      warnOutsideInjectionContext(it, "ɵzoneWrap");
      return it.apply(this, _arguments);
    }
    // if this is a callback function, e.g, onSnapshot, we should create a pending task and complete it
    // only once one of the callback functions is tripped.
    for (let i = 0; i < arguments.length; i++) {
      if (typeof _arguments[i] === 'function') {
        if (blockUntilFirst) {
          taskDone ||= run(() => pendingTasks.add());
        }
        // TODO create a microtask to track callback functions
        _arguments[i] = zoneWrapFn(_arguments[i], taskDone, injector);
      }
    }
    const ret = runOutsideAngular(() => it.apply(this, _arguments));
    if (!blockUntilFirst) {
      if (ret instanceof Observable) {
        return ret.pipe(subscribeOn(schedulers.outsideAngular), observeOn(schedulers.insideAngular));
      } else {
        return run(() => ret);
      }
    }
    if (ret instanceof Observable) {
      return ret.pipe(subscribeOn(schedulers.outsideAngular), observeOn(schedulers.insideAngular), pendingUntilEvent(injector));
    } else if (ret instanceof Promise) {
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      return run(() => new Promise((resolve, reject) => {
        pendingTasks.run(() => ret).then(it => runInInjectionContext(injector, () => run(() => resolve(it))), reason => runInInjectionContext(injector, () => run(() => reject(reason))));
      }));
    } else if (typeof ret === 'function' && taskDone) {
      // Handle unsubscribe
      // function() is needed for the arguments object
      return function () {
        setTimeout(taskDone, 0);
        return ret.apply(this, arguments);
      };
    } else {
      // TODO how do we handle storage uploads in Zone? and other stuff with cancel() etc?
      return run(() => ret);
    }
  };
};

/**
 * Generated bundle index. Do not edit.
 */

export { VERSION, observeInsideAngular, observeOutsideAngular, ɵAPP_CHECK_PROVIDER_NAME, ɵAngularFireSchedulers, ɵAppCheckInstances, ɵZoneScheduler, ɵgetAllInstancesOf, ɵgetDefaultInstanceOf, ɵisSupportedError, ɵzoneWrap };
